<!--邀请页面-->
<template>
    <div class="body">
        <div class="invite-wrapper">
            <p class="invite-title">邀请好友成为你的生活家</p>
            <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/invitation_rule.png" class="invite-img">
            <div class="invite-option-wrapper">
<!--                <div class="invite-option-item-wrapper" @click="showSharePop()">-->
<!--                    <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/invitation_icon1.png">-->
<!--                    <p>邀请微信好友</p>-->
<!--                </div>-->
                <router-link class="invite-option-item-wrapper" to="/distribution/poster">
                    <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/invitation_icon2.png">
                    <p>获取海报邀请</p>
                </router-link>
            </div>
        </div>
        <share-pop ref="sharePop"></share-pop>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import SharePop from '../../components/common/SharePop'
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'Invite',
    components: { FloatNav, SharePop },
    methods: {
      showSharePop: function () {
        this.$refs.sharePop.showPop()
      }
    },
    mounted () {
      console.log(this.$refs.sharePop)
    }
  }
</script>

<style scoped>
    *{
        box-sizing: border-box;
    }
    .body{
        position: absolute;
        background: white;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/分享背景v2.png") no-repeat;
        background-size: 100% ;
    }
    .invite-wrapper{
        width: 90vw;
        padding: 5vw 5.5vw;
        margin:  60vw 5vw 0;
        border-radius: 5vw;
        box-shadow: 0 0 10px #EFE8E3;
        background-color: #FFFFff;
    }
    .invite-title{
        font-size: 5.2vw;
        font-weight: 600;
        text-align: center;
    }
    .invite-img{
        width: 78vw;
        margin: 2vw 0;
    }
    .invite-option-wrapper{
        display: flex;
        justify-content: space-around;
    }
    .invite-option-item-wrapper{
        width: 41vw;
    }
    .invite-option-item-wrapper img{
        width: 17vw;
        margin: 0 12vw;
    }
    .invite-option-item-wrapper p{
        width: 33vw;
        height: 9vw;
        line-height: 9vw;
        border-radius: 9vw;
        text-align: center;
        font-size: 3.2vw;
        background-color: #f1a417;
        color: #fff;
        margin: 0 4vw;
    }
</style>
